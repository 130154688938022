export interface IList<T> {
    filters: { [key: string]: any,  page: number };
    models: T[];
    next_page?: number;
    per_page?: number;
    total: number;
    fetching: boolean;
}

export default <T>(initialFilters: {[key: string]: any} = {}): IList<T> => {
  const filters = {
      page: 0,
      ...initialFilters,
  };
  return {
    filters,
    fetching: false,
    models: [],
    total: 0,
  };
};
